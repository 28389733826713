.usercard {
  background: var(--fixbot-blue);
  color: var(--white);
  border-radius: 16px;
  padding: 20px;
  position: relative;
  overflow: hidden;
}

.usercard__inner {
  position: relative;
  z-index: 4;
}

.usercard__inner h2 {
  margin-bottom: -10px;
}

/* .usercard::after {
    position: absolute;
    top: -60px;
    right: -30px;
    width: 150px;
    border-radius: 200px;
    height: 150px;
    content: "";
    border: 1px solid rgba(212, 249, 211, 0.5);
    background: linear-gradient(37.06deg, #7EC67D 21.51%, #74B973 80.73%);
    opacity: 0.5;
}

.usercard__secondCircle,
.usercard__circle,
.usercard::before {
    position: absolute;
    width: 259.21px;
    height: 273.37px;
    border-radius: 200px;
    right: 5px;
    top: 153px;
    z-index: 2;
    border: 1px solid rgba(212, 249, 211, 0.5);
    content: "";
    background: linear-gradient(238.41deg, #92DD91 -5.76%, rgba(116, 185, 115, 0) 54.39%);
    opacity: 0.5;
}

.usercard__secondCircle,
.usercard__circle {
    background: linear-gradient(137.76deg, #FFFFFF 18.03%, rgba(255, 255, 255, 0) 92.51%);
    opacity: 0.1;
}

.usercard__circle {
    left: -120px;
    top: -20px;
}

.usercard__secondCircle {
    right: -190px;
    top: -50px;
} */
