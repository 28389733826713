.sidebar {
  height: 100vh;
  width: 100%;
  position: sticky;
  left: 0;
  top: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.03);
  background: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
  z-index: 10;
  transition: all 300ms ease;
}

.sidebar.sidebarSmall {
  width: 90px;
}
