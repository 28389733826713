.wrapper {
  padding: 30px 10px 20px;
  width: 96%;
  max-width: 1350px;
  margin: auto;
  position: relative;
  display: grid;
  grid-column-gap: 15px;
  grid-template-columns: 1fr minmax(180px, 300px);
}

/* .wrapper > div {
  overflow-x: hidden;
} */
.wrapper .left {
  padding-top: 4px;
  padding-left: 4px;
}

.bottomButton {
  display: none;
}

.wrapper .right {
  position: relative;
  margin-left: 30px;
}
@media (max-width: 1150px) {
  .wrapper {
    grid-template-columns: 1fr;
  }

  .wrapper .right {
    display: none;
  }
  .wrapper .left {
    padding-left: 0px;
  }
}

@media (max-width: 766px) {
  .bottomButton {
    display: block;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
