.driverRating {
    background: url("../../assets/svg/rating.svg") no-repeat;
    background-size: cover;
    background-position: center center;
    min-height: 200px;
    background-color: var(--fixbot-blue);
}

.rating {
    position: relative;
    border: 7px solid rgba(227, 36, 36, 0.3);
    display: grid;
    place-items: center;
}

.rating::after {
    position: absolute;
    width: 100%;
    height: 100%;
    /* background-color: red; */
    /* content: ""; */
    /* border-radius: 100px; */
}