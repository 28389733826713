.date-picker {
  background: red;
  width: 150px;
  outline: none;
  height: 50.5px;
  opacity: 0;
  /* visibility: hidden; */
  cursor: pointer;
}

.date-picker * {
  opacity: 1 !important;
}
