:root {
  --bg-color: #e5e5e5;
  --white: #fff;
  --fixbot-blue: #213654;
  --light-gray: #b5afaf;
  --danger-color: #e32424;
  --text-color: #333;
  --card-grey: #c4c4c4;

  /* Brealpoints */
  /* background: #E3242494;
    background: #FCD561;
    background: #D4F9D3; */
}

.dashboard__wrapper {
  min-height: 100vh;
  display: flex;
  color: var(--text-color);
  position: relative;
  /* position: sticky;
  top: 0;
  left: 0;
  position="sticky" right={0} top={0} */
  transition: all 300ms ease;
}

.omo > div {
  position: relative !important;
}
.dashboard__rightSection {
  transition: all 300ms ease;
  flex: 1;
  /* background-color: #ebf3f3; */
  /* width: 100%; */
}

.dashboard__spinner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin-top: 30px;
}

/* green btn
#91D738 */

/* #7EC67D */
/* #92DD91 */
/* #C0E1BF */
/* #D4F9D3 */
.dashboard__smallSidebar {
  transition: all 300ms ease;
}
