@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  scroll-behavior: smooth;
  background: #fff;
}

nav {
  display: flex;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  height: 8vh;
  background: #fff;
  box-shadow: 0px 1px 0px #E5E9F2;
  position: relative;
  z-index: 2;
  padding: 2%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
nav .logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
nav .logo img {
  margin-right: 3%;
  height: 48px;
}
nav .nav-links {
  display: flex;
  width: 70%;
  justify-content: space-between;
  text-transform: capitalize;
}
nav .nav-links li {
  list-style: none;
}
nav .nav-links a {
  color: #BFAFAF;
  text-decoration: none;
  letter-spacing: 3px;
  font-weight: 700;
  font-size: 1rem;
  position: relative;
  font-family: "Poppins", sans-serif;
}
nav .nav-links a:hover {
  opacity: 0.9;
}
nav .nav-links a:focus {
  opacity: 0.7;
}
nav .nav-links a::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  top: 25px;
  width: 0%;
  height: 2px;
  border-radius: 10px;
  margin: auto;
  transition: all 0.4s;
  opacity: 0;
  background-color: #000;
}
nav .nav-links a:hover::after {
  width: 100%;
  opacity: 1;
}
nav .active {
  color: #000 !important;
}
nav .burger {
  display: none;
  cursor: pointer;
  z-index: 99;
}
nav .burger .line0 {
  width: 30px;
  height: 3px;
  background: #213654;
  margin: 5px;
  border-radius: 8px;
  transition: all 0.3s ease;
}
nav .burger .line1 {
  width: 30px;
  height: 3px;
  background: #213654;
  margin: 5px;
  border-radius: 8px;
  transition: all 0.3s ease;
}
nav .burger .line2 {
  width: 30px;
  height: 3px;
  background: #213654;
  margin: 5px;
  border-radius: 8px;
  transition: all 0.3s ease;
}
nav .bro {
  display: none;
  cursor: pointer;
  z-index: 99;
}
nav .bro .line0 {
  width: 30px;
  height: 3px;
  margin: 5px;
  border-radius: 8px;
  transform: rotate(-45deg) translate(-5px, 6px);
  background: #213654;
  transition: all 0.3s ease;
}
nav .bro .line1 {
  width: 30px;
  height: 3px;
  background: #213654;
  margin: 5px;
  border-radius: 8px;
  transition: all 0.3s ease;
  opacity: 0;
}
nav .bro .line2 {
  width: 30px;
  height: 3px;
  background: #213654;
  margin: 5px;
  border-radius: 8px;
  transition: all 0.3s ease;
  transform: rotate(42deg) translate(-5px, -7px);
  margin-top: 5px;
}
nav .login a {
  color: #223256;
  border-radius: 12px;
  padding: 10px 15px;
}
nav .login a:hover {
  background: #223256;
  color: #fff !important;
  opacity: 1 !important;
}
nav .login a::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  top: 25px;
  width: 0%;
  height: 0px;
  border-radius: 10px;
  margin: auto;
  transition: all 0.4s;
}
nav .request a {
  color: #223256;
  border-radius: 12px;
  padding: 10px 15px !important;
  background: #223256;
  color: #fff !important;
}
nav .request a:hover {
  opacity: 0.8;
}
nav .request a::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  top: 25px;
  width: 0%;
  height: 0px;
  border-radius: 10px;
  margin: auto;
  transition: all 0.4s;
}

@media screen and (max-width: 1024px) {
  nav .nav-links {
    width: 90%;
  }
  nav .nav-links a {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 768px) {
  nav .nav-links {
    display: none;
  }
  nav .nav-links li {
    opacity: 0;
    margin-top: 0;
    font-size: 12px;
  }
  nav .burger,
nav .bro {
    display: block;
  }
  nav .logo {
    z-index: 5;
  }
  nav .logo img {
    height: 25px;
    align-self: center;
    justify-self: center;
    margin-top: 5.5px;
  }

  @-webkit-keyframes navlinkFade {
    from {
      opacity: 0;
      transform: translateX(50px);
    }
    to {
      opacity: 1;
      transform: translateX(0px);
    }
  }

  @keyframes navlinkFade {
    from {
      opacity: 0;
      transform: translateX(50px);
    }
    to {
      opacity: 1;
      transform: translateX(0px);
    }
  }
}
.nav-active {
  transform: translate(0%);
  position: absolute;
  right: 0;
  height: 90vh;
  top: 0;
  background: #fff;
  color: #573F7B;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  transition: transform 0.5s ease-in;
  z-index: 4;
}
.nav-active li {
  list-style: none;
}
.nav-active a {
  color: #BFAFAF;
  text-decoration: none;
  letter-spacing: 3px;
  font-weight: 700;
  font-size: 1rem;
  position: relative;
  font-family: "Poppins", sans-serif;
}
.nav-active a:hover {
  opacity: 0.9;
}
.nav-active a:focus {
  opacity: 0.7;
}
.nav-active a::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  top: 25px;
  width: 0%;
  height: 2px;
  border-radius: 10px;
  margin: auto;
  transition: all 0.4s;
  opacity: 0;
  background-color: #000;
}
.nav-active a:hover::after {
  width: 100%;
  opacity: 1;
}

h1 {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 2.6rem;
  color: #333333;
}

p {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 24px;
  color: #6E6E6E;
}

h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 45px;
  color: #333333;
}

@media screen and (max-width: 1024px) {
  h1 {
    font-size: 1.8rem;
  }

  p {
    font-size: 0.8rem;
  }

  h3 {
    font-size: 1.5rem;
  }
}
video {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.my-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .my-container {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .my-container {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .my-container {
    width: 960px;
  }
}
@media (min-width: 1200px) {
  .my-container {
    width: 1140px;
  }
}
.newsletterform {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.newsletterform .email-con {
  width: 70%;
}
.newsletterform .email-con input {
  width: 100%;
  padding: 2%;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: #213654;
  border: 1px solid #D4D4D4;
  border-radius: 10px 0px 0px 12px;
  outline: none;
}
.newsletterform .email-con input::-moz-placeholder {
  color: #BFAFAF;
  font-family: "Poppins", sans-serif;
}
.newsletterform .email-con input:-ms-input-placeholder {
  color: #BFAFAF;
  font-family: "Poppins", sans-serif;
}
.newsletterform .email-con input::placeholder {
  color: #BFAFAF;
  font-family: "Poppins", sans-serif;
}
.newsletterform .email-con input:focus {
  border: 1px solid #213654;
}
.newsletterform .submit-btn {
  width: 30%;
}
.newsletterform .submit-btn input {
  width: 100%;
  padding: 4.7%;
  border: 1px solid #213654;
  background: #213654;
  color: #fff;
  outline: none;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.0168em;
  border-radius: 0px 10px 12px 0px;
}
.newsletterform .submit-btn input:hover {
  background: none;
  color: #213654;
  border: 1px solid #213654;
}

@media screen and (max-width: 1024px) {
  .newsletterform .email-con input {
    font-size: 0.8rem;
  }
  .newsletterform .submit-btn {
    width: 30%;
  }
  .newsletterform .submit-btn input {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 768px) {
  .newsletterform {
    flex-direction: column;
  }
  .newsletterform .email-con {
    width: 100%;
  }
  .newsletterform .email-con input {
    width: 100%;
    border-radius: 10px 10px 10px 10px;
    outline: none;
  }
  .newsletterform .submit-btn {
    margin-top: 5%;
    width: 80%;
  }
  .newsletterform .submit-btn input {
    border-radius: 10px 10px 10px 10px;
    padding: 2.5%;
  }
  .newsletterform .submit-btn input:hover {
    background: none;
    color: #213654;
    border: 1px solid #213654;
  }
}
.banner {
  background: #EBF3F3;
  width: 100%;
  padding-top: 5%;
  padding-bottom: 5%;
  margin-top: 3%;
  margin-top: 8vh;
}
.banner .banner-inner {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.banner h1,
.banner p,
.banner h3 {
  text-align: center;
  margin-bottom: 2.5%;
}

@media screen and (max-width: 768px) {
  .banner {
    margin-top: 8vh;
  }
  .banner .banner-inner {
    align-items: flex-start;
  }
  .banner h3 {
    text-align: center;
    margin-left: 50%;
    margin-right: 50%;
    transform: translate(-50%);
    width: 100%;
  }
  .banner h1,
.banner p {
    text-align: left;
  }
}
.about-videos {
  width: 100%;
  padding-top: 5%;
  padding-bottom: 5%;
}
.about-videos .about-videos-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.about-videos .about-videos-inner .about {
  padding: 2%;
}
.about-videos .about-videos-inner div {
  width: 48%;
  box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.08);
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 12px;
}
.about-videos .about-videos-inner div a,
.about-videos .about-videos-inner div p,
.about-videos .about-videos-inner div h3 {
  text-align: center;
  margin-bottom: 5%;
}
.about-videos .about-videos-inner div a {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  color: #000000;
  text-decoration: none;
}
.about-videos .about-videos-inner div a:hover {
  opacity: 0.8;
}
.about-videos .about-videos-inner div video {
  border-radius: 12px;
}

@media screen and (max-width: 1024px) {
  .about-videos .about-videos-inner div a {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 768px) {
  .about-videos .about-videos-inner {
    flex-direction: column;
  }
  .about-videos .about-videos-inner .about {
    padding: 5%;
  }
  .about-videos .about-videos-inner .about a,
.about-videos .about-videos-inner .about h3 {
    text-align: center;
    margin-bottom: 2%;
  }
  .about-videos .about-videos-inner .about P {
    text-align: left;
  }
  .about-videos .about-videos-inner .video-con {
    margin-top: 5%;
    height: 300px;
  }
  .about-videos .about-videos-inner div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 12px;
    padding: 0%;
  }
}
.features {
  padding-top: 5%;
  padding-bottom: 5%;
  background: #EBF3F3;
}
.features .features-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.features .features-inner h1,
.features .features-inner p {
  text-align: center;
  margin-bottom: 2.5%;
}
.features .features-inner .cont {
  width: 80%;
}
.features .features-inner .cont .feature {
  width: 100%;
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
  background: #F2FAFA;
  padding: 1%;
}
.features .features-inner .cont .feature h3 {
  text-align: center;
}
.features .features-inner .cont .feature1 {
  width: 100%;
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
  background: #fff;
}
.features .features-inner .cont .feature1 p {
  border-bottom: 1px solid #D4D4D4;
  padding: 5%;
  margin-bottom: 0;
}
.features .features-inner .cont .feature1 .green {
  color: #91D738;
}

@media screen and (max-width: 1024px) {
  .features .features-inner h1 {
    font-size: 1.5rem;
  }
  .features .features-inner .cont .feature h3 {
    font-size: 1rem;
    line-height: 1;
  }
  .features .features-inner .cont .feature1 p {
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 768px) {
  .features .features-inner .cont {
    width: 100%;
  }
  .features .features-inner .cont .feature {
    padding: 3%;
  }
  .features .features-inner .cont .feature h3 {
    text-align: center;
  }
  .features .features-inner .cont .feature1 {
    width: 100%;
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
    background: #fff;
    padding: 3%;
  }
  .features .features-inner .cont .feature1 .left {
    text-align: left;
  }
  .features .features-inner .cont .feature1 .green {
    color: #91D738;
  }
}
.why-choose {
  padding-top: 5%;
  padding-bottom: 5%;
}
.why-choose .why-choose-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.why-choose .why-choose-inner h3,
.why-choose .why-choose-inner p {
  text-align: center;
  margin-bottom: 2.5%;
}
.why-choose .why-choose-inner .reason-cont {
  width: 100%;
  margin-top: 5%;
}
.why-choose .why-choose-inner .reason-cont .resort {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  background: #FFFFFF;
}
.why-choose .why-choose-inner .reason-cont .resort .resort-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 120px;
  padding: 5%;
  border-radius: 10px;
  margin-bottom: 5%;
  box-shadow: 10px 32px 50px rgba(51, 51, 51, 0.03);
}
.why-choose .why-choose-inner .reason-cont .resort .resort-inner .fin {
  width: 20%;
  height: 100%;
}
.why-choose .why-choose-inner .reason-cont .resort .resort-inner .fin img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.why-choose .why-choose-inner .reason-cont .resort .resort-inner .p2 {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.why-choose .why-choose-inner .reason-cont .resort .resort-inner .p2 p {
  text-align: left;
}
.why-choose .why-choose-inner .reason-cont .resort .resort-inner .p2 .bold {
  font-weight: bold;
  font-size: 0.8rem;
}
.why-choose .why-choose-inner .reason-cont .resort .resort-inner .p2 .pale {
  font-size: 0.8rem;
}

@media screen and (max-width: 1024px) {
  .why-choose .why-choose-inner .reason-cont .resort .resort-inner {
    height: 120px;
    padding: 5%;
  }
  .why-choose .why-choose-inner .reason-cont .resort .resort-inner .p2 .pale {
    font-size: 0.6rem;
  }
}
@media screen and (max-width: 768px) {
  .why-choose .why-choose-inner .reason-cont {
    width: 100%;
    margin-top: 5%;
  }
  .why-choose .why-choose-inner .reason-cont .resort {
    display: grid;
    grid-template-columns: 50% 50%;
    background: #FFFFFF;
  }
  .why-choose .why-choose-inner .reason-cont .resort .resort-inner {
    height: 120px;
    padding: 5%;
  }
  .why-choose .why-choose-inner .reason-cont .resort .resort-inner .p2 {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
  .why-choose .why-choose-inner .reason-cont .resort .resort-inner .p2 p {
    text-align: left;
  }
  .why-choose .why-choose-inner .reason-cont .resort .resort-inner .p2 .bold {
    font-weight: bold;
  }
}
@media screen and (max-width: 540px) {
  .why-choose .why-choose-inner .reason-cont {
    width: 100%;
    margin-top: 5%;
  }
  .why-choose .why-choose-inner .reason-cont .resort {
    display: grid;
    grid-template-columns: auto;
    background: #FFFFFF;
  }
  .why-choose .why-choose-inner .reason-cont .resort .resort-inner {
    height: 100px;
    padding: 5%;
  }
  .why-choose .why-choose-inner .reason-cont .resort .resort-inner .p2 {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
  .why-choose .why-choose-inner .reason-cont .resort .resort-inner .p2 p {
    text-align: left;
  }
  .why-choose .why-choose-inner .reason-cont .resort .resort-inner .p2 .bold {
    font-weight: bold;
  }
}
.products {
  padding-top: 5%;
  padding-bottom: 5%;
  background: #EBF3F3;
}
.products .inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.products .inner h3,
.products .inner p {
  text-align: center;
  margin-bottom: 2.5%;
}
.products .inner .product {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  margin-top: 5%;
}
.products .inner .product .Standard {
  background: #213654;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0px 8px 32px rgba(59, 59, 59, 0.04) !important;
  width: 30%;
  padding: 2%;
  border-radius: 16px;
}
.products .inner .product .Standard .icon {
  color: #F4BC4D;
}
.products .inner .product .Standard .starred {
  display: block;
  margin-bottom: 5%;
  margin-top: 5%;
  font-size: 0.75rem;
}
.products .inner .product .Standard h1 {
  font-size: 2.5rem;
}
.products .inner .product .Standard h3 {
  font-size: 1.8rem;
}
.products .inner .product .Standard p {
  font-size: 0.875rem;
}
.products .inner .product .Standard h1,
.products .inner .product .Standard h3,
.products .inner .product .Standard p {
  color: #fff;
}
.products .inner .product .Standard .snip {
  display: block;
  width: 100%;
  margin-bottom: 5%;
  margin-top: 5%;
}
.products .inner .product .Standard div {
  margin-bottom: 5%;
  width: 90%;
}
.products .inner .product .Standard div p {
  text-align: left;
  color: #fff;
  margin-bottom: 5%;
}
.products .inner .product .Standard div p .con {
  color: #fff;
}
.products .inner .product .Standard a {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 30px;
  text-align: center;
  background: #fff;
  color: #213654;
  display: block;
  width: 100%;
  text-decoration: none;
  padding: 2%;
  margin-top: 5%;
  border-radius: 12px;
  margin-bottom: 5%;
}
.products .inner .product .Standard a:hover {
  border: 1px solid #fff;
  background: none;
  color: #fff;
}
.products .inner .product .Basic,
.products .inner .product .Enterprise {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0px 8px 32px rgba(59, 59, 59, 0.04) !important;
  width: 30%;
  padding: 2%;
  border-radius: 16px;
  background: #fff;
}
.products .inner .product .Basic .starred,
.products .inner .product .Enterprise .starred {
  display: none;
}
.products .inner .product .Basic .icon,
.products .inner .product .Enterprise .icon {
  color: #F4BC4D;
}
.products .inner .product .Basic h1,
.products .inner .product .Enterprise h1 {
  font-size: 2.5rem;
}
.products .inner .product .Basic h3,
.products .inner .product .Enterprise h3 {
  font-size: 1.8rem;
}
.products .inner .product .Basic p,
.products .inner .product .Enterprise p {
  font-size: 0.875rem;
}
.products .inner .product .Basic h1,
.products .inner .product .Basic h3,
.products .inner .product .Basic p,
.products .inner .product .Enterprise h1,
.products .inner .product .Enterprise h3,
.products .inner .product .Enterprise p {
  text-align: center;
  margin-bottom: 2.5%;
}
.products .inner .product .Basic .snip,
.products .inner .product .Enterprise .snip {
  display: block;
  width: 100%;
  margin-bottom: 5%;
  margin-top: 5%;
}
.products .inner .product .Basic div,
.products .inner .product .Enterprise div {
  margin-bottom: 5%;
  width: 90%;
}
.products .inner .product .Basic div p,
.products .inner .product .Enterprise div p {
  text-align: left;
  color: #6E6E6E;
  margin-bottom: 3%;
}
.products .inner .product .Basic div p .con,
.products .inner .product .Enterprise div p .con {
  color: #213654;
}
.products .inner .product .Basic a,
.products .inner .product .Enterprise a {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 30px;
  text-align: center;
  background: #213654;
  color: #FFFFFF;
  display: block;
  width: 100%;
  text-decoration: none;
  padding: 2%;
  margin-top: 5%;
  border-radius: 12px;
}
.products .inner .product .Basic a:hover,
.products .inner .product .Enterprise a:hover {
  border: 1px solid #213654;
  background: none;
  color: #213654;
}

@media screen and (max-width: 1024px) {
  .products .inner .product .Standard .icon {
    color: #F4BC4D;
  }
  .products .inner .product .Standard .starred {
    font-size: 0.625rem;
  }
  .products .inner .product .Standard h1 {
    font-size: 2rem;
  }
  .products .inner .product .Standard h3 {
    font-size: 1.2rem;
  }
  .products .inner .product .Standard p {
    font-size: 0.75rem;
  }
  .products .inner .product .Standard a {
    font-size: 1rem;
  }
  .products .inner .product .Basic h1,
.products .inner .product .Enterprise h1 {
    font-size: 2rem;
  }
  .products .inner .product .Basic h3,
.products .inner .product .Enterprise h3 {
    font-size: 1.2rem;
  }
  .products .inner .product .Basic p,
.products .inner .product .Enterprise p {
    font-size: 0.75rem;
  }
  .products .inner .product .Basic a,
.products .inner .product .Enterprise a {
    font-size: 1rem;
  }
}
@media screen and (max-width: 768px) {
  .products .inner .product {
    flex-direction: column;
  }
  .products .inner .product .Standard {
    width: 100%;
    padding: 5%;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .products .inner .product .Standard .starred {
    margin-bottom: 5%;
    margin-top: 5%;
  }
  .products .inner .product .Standard .snip {
    display: block;
    width: 100%;
    margin-bottom: 5%;
    margin-top: 5%;
  }
  .products .inner .product .Standard div {
    margin-bottom: 5%;
    width: 100%;
    padding: 5%;
  }
  .products .inner .product .Standard div p {
    text-align: left;
    color: #fff;
    margin-bottom: 5%;
  }
  .products .inner .product .Standard div p .con {
    color: #fff;
  }
  .products .inner .product .Standard a {
    padding: 2%;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .products .inner .product .Basic,
.products .inner .product .Enterprise {
    width: 100%;
    padding: 5%;
  }
  .products .inner .product .Basic div,
.products .inner .product .Enterprise div {
    width: 100%;
    padding: 5%;
  }
  .products .inner .product .Basic div p,
.products .inner .product .Enterprise div p {
    text-align: left;
    color: #6E6E6E;
    margin-bottom: 3%;
  }
  .products .inner .product .Basic a,
.products .inner .product .Enterprise a {
    padding: 2%;
    margin-top: 5%;
    margin-bottom: 5%;
  }
}
.testimonies {
  padding-top: 5%;
  padding-bottom: 5%;
}
.testimonies .inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.testimonies .inner h1,
.testimonies .inner p {
  text-align: center;
  margin-bottom: 2.5%;
}
.testimonies .inner .testimony {
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
  margin-top: 2%;
}
.testimonies .inner .testimony .bless .comment-cont {
  padding: 5%;
  box-shadow: 10px 32px 72px rgba(51, 51, 51, 0.03);
  border-radius: 10px;
}
.testimonies .inner .testimony .bless .comment-cont p {
  text-align: left;
}
.testimonies .inner .testimony .bless .nam-rat {
  margin-top: 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5%;
}
.testimonies .inner .testimony .bless .nam-rat .nam-rat-in {
  display: flex;
  align-items: center;
}
.testimonies .inner .testimony .bless .nam-rat .nam-rat-in .img-con {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.testimonies .inner .testimony .bless .nam-rat .nam-rat-in .img-con img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.testimonies .inner .testimony .bless .nam-rat .nam-rat-in .name-con p {
  text-align: left;
  margin-left: 10px;
  font-family: "Ubuntu", sans-serif;
}
.testimonies .inner .testimony .bless .nam-rat .nam-rat-in .name-con .bold {
  font-weight: bold;
}
.testimonies .inner .testimony .bless .nam-rat .nam-rat-in .name-con .pale {
  font-size: 0.75rem;
}
.testimonies .inner .testimony .bless .nam-rat .rating-con {
  display: grid;
  grid-template-columns: auto auto;
}
.testimonies .inner .testimony .bless .nam-rat .rating-con .icon {
  color: #F4BC4D;
}
.testimonies .inner .testimony .bless .nam-rat .rating-con p {
  font-family: "Ubuntu", sans-serif;
  color: #D4D4D4;
  margin-left: 10px;
}

.options {
  background: #EBF3F3;
  padding-top: 5%;
  padding-bottom: 5%;
}
.options h3,
.options p {
  margin-bottom: 1%;
  color: #fff;
}
.options .inner {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.options .inner div {
  padding: 5%;
  border-radius: 20px;
}
.options .inner .mechanic {
  background: url("../blue.svg");
  background-size: cover;
  background-repeat: no-repeat;
}
.options .inner .mechanic a {
  text-decoration: none;
  background: #fff;
  width: 20%;
  padding: 1%;
  border-radius: 10px;
  margin-top: 2%;
  text-align: center;
  display: block;
  color: #292B4D;
}
.options .inner .mechanic a:hover {
  border: 1px solid #fff;
  color: #fff;
  background: none;
  transform: translateX(10px);
  transition: all 1s;
}
.options .inner .spare {
  margin-top: 2%;
  background: url("../green.svg");
  background-size: cover;
  background-repeat: no-repeat;
}
.options .inner .spare a {
  text-decoration: none;
  background: #292B4D;
  width: 20%;
  padding: 1%;
  border-radius: 10px;
  margin-top: 2%;
  text-align: center;
  display: block;
  color: #fff;
}
.options .inner .spare a:hover {
  border: 1px solid #292B4D;
  color: #292B4D;
  background: none;
  transform: translateX(10px);
  transition: all 1s;
}

.waitlist {
  padding-top: 5%;
  padding-bottom: 5%;
  background: #fff;
}
.waitlist .inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.waitlist .inner h3,
.waitlist .inner p {
  text-align: center;
  margin-bottom: 2.5%;
}
.waitlist .inner .news-con {
  width: 80%;
  padding: 3%;
  background: #EBF3F3;
  border-radius: 16px;
  border: 1px solid #D4D4D4;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.waitlist .inner .news-con p {
  margin-top: 3%;
  font-size: 0.8rem;
}
.waitlist .inner .news-con p a {
  text-decoration: underline;
  color: #213654;
  font-weight: bold;
  text-transform: capitalize;
}
.waitlist .inner .news-con p a:hover {
  opacity: 0.8;
}

footer {
  background: #EBF3F3;
  padding-top: 5%;
  padding-bottom: 5%;
}
footer .inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
footer .inner .foot-cont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
footer .inner .foot-cont .cont {
  width: 30%;
  height: 100%;
}
footer .inner .foot-cont .cont .img-con {
  height: 48px;
  width: 50%;
}
footer .inner .foot-cont .cont .img-con img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
footer .inner .foot-cont .cont p {
  margin-top: 0%;
  margin-left: 5%;
  color: #333333;
  font-size: 0.8rem;
}
footer .inner .foot-cont .cont .icons-con {
  margin-left: 5%;
  margin-top: 1%;
  width: 100%;
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
footer .inner .foot-cont .cont .icons-con a {
  color: #333;
  text-decoration: none;
  font-size: 1.5rem;
}
footer .inner .foot-cont .cont .icons-con a:hover {
  opacity: 0.8;
}
footer .inner .foot-cont .links-main {
  width: 70%;
  display: grid;
  grid-template-columns: auto auto auto;
}
footer .inner .foot-cont .links-main div h1 {
  font-size: 1.8rem;
  font-weight: 600;
  color: #333333;
}
footer .inner .foot-cont .links-main div p {
  margin-top: 2%;
  color: #333333;
  font-size: 0.8rem;
}
footer .inner .foot-cont .links-main div p a {
  text-decoration: none;
  color: #333333;
  position: relative;
}
footer .inner .foot-cont .links-main div p a:hover {
  opacity: 0.9;
}
footer .inner .foot-cont .links-main div p a:focus {
  opacity: 0.7;
}
footer .inner .foot-cont .links-main div p a::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  top: 25px;
  width: 0%;
  height: 2px;
  border-radius: 10px;
  margin: auto;
  transition: all 0.4s;
  opacity: 0;
  background-color: #333;
}
footer .inner .foot-cont .links-main div p a:hover::after {
  width: 100%;
  opacity: 1;
}
footer .inner .rights {
  margin-top: 2%;
  border-top: 1px solid #6E6E6E;
  width: 100%;
  padding: 2%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
footer .inner .rights p {
  font-size: 0.8rem;
}
footer .inner .rights p a {
  text-decoration: none;
  color: #333333;
  position: relative;
}
footer .inner .rights p a:hover {
  opacity: 0.9;
}
footer .inner .rights p a:focus {
  opacity: 0.7;
}
footer .inner .rights p a::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  top: 25px;
  width: 0%;
  height: 2px;
  border-radius: 10px;
  margin: auto;
  transition: all 0.4s;
  opacity: 0;
  background-color: #333;
}
footer .inner .rights p a:hover::after {
  width: 100%;
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .testimonies,
.options,
.waitlist,
footer {
    display: none;
  }
}/*# sourceMappingURL=style.css.map */