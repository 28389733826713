.diagnostics__top {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* grid-template-columns: 270px minmax(100px, 1fr);
  grid-template-rows: 270px;
  grid-gap: 15px; */
}

@media (max-width: 700px) {
  .diagnostics__top {
    grid-template-columns: 1fr;
    grid-template-rows: max-content 270px;
  }

  .diagnostics__top > div:first-child {
    order: 2;
  }
}
