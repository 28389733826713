.dashboard__landing {
  display: flex;
  flex-direction: column;
}

.dashboard__mainSection {
  display: grid;
  grid-template-columns: 240px minmax(100px, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 10px;
  margin-bottom: 30px;
  align-items: flex-start;
}

@media (max-width: 766px) {
  .dashboard__mainSection {
    display: grid;
    grid-template-columns: 1fr;
    order: 2;
  }

  .dashboard__goals {
    order: 3;
  }

  .dashboard__mainSection > div:first-child {
    /* display: none; */
    padding-bottom: 20px;
    margin-top: 20px;
  }
}
