.navbar {
  width: 100%;
  height: 50px;
  background: var(--white);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  align-items: center;
  display: flex;
  position: sticky;
  right: 0;
  top: 0;
  z-index: 999;
}

.navbar__inner {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
}

.navbar__icons svg {
  color: #888;
  width: 18px;
  height: 18px;
}
