.sidebarLink__iconMode,
.sidebarLink {
  width: 100%;
  padding: 0px 0px;
  padding-left: 12px;
  margin-bottom: 5px;
  display: flex;
  border-right: 3px solid transparent;
  height: 50px;
  text-transform: capitalize;
  color: var(--light-gray);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: all 300ms linear !important;
}

.sidebarLink__iconMode img,
.sidebarLink img {
  transition: all 300ms ease;
}

/* For the normal sidebar */
.sidebarLink {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

/* hover */
.sidebarLink:hover p {
  color: var(--fixbot-blue);
}

.sidebarLink:hover img {
  filter: grayscale(0) !important;
  opacity: 1;
}

/* Active normal sidebar */
.sidebarLink.active {
  border-color: var(--fixbot-blue);
  border-bottom-color: rgba(0, 0, 0, 0.1);
}

.sidebarLink.active p {
  color: var(--fixbot-blue);
}

/* Icon Mode unique styling */
.sidebarLink__iconMode {
  padding: 0px 9px;
  border-right: 0px solid transparent;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.sidebarLink__iconMode:hover,
.sidebarLink:hover {
  color: var(--fixbot-blue);
}

.sidebarLink__iconMode:hover img {
  filter: grayscale(0) !important;
  opacity: 0.8;
}

/* End of Icon mode styling */

.sidebarLink span {
  margin-right: 13px;
}

button.sidebarLink {
  display: flex;
  justify-content: flex-start;
  background-color: var(--white);
  text-align: left;
  font-weight: normal;
}

button.sidebarLink img {
  filter: grayscale(0%);
}

button.sidebarLink p {
  color: var(--danger-color);
}

.sidebarLink__iconMode:not(a) {
  color: var(--danger-color);
}

.sidebarLink__iconMode img,
.sidebarLink img {
  filter: grayscale(100%);
  opacity: 0.5;
}

/* Active class styling */
.sidebarLink__iconMode.active {
  background-color: white;
  border-right: 3px solid var(--fixbot-blue);
}

.sidebarLink__iconMode.active img,
.sidebarLink.active img {
  filter: grayscale(0);
  opacity: 0.9;
}
