@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    scroll-behavior: smooth;
    background: #fff;
}

nav {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
    height: 8vh;
    background: #fff;
    box-shadow: 0px 1px 0px #E5E9F2;
    position: relative;
    z-index: 2;
    padding: 2%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            margin-right: 3%;
            height: 48px;
        }
    }

    .nav-links {
        display: flex;
        width: 70%;
        justify-content: space-between;
        text-transform: capitalize;
    }

    .nav-links li {
        list-style: none;
    }

    .nav-links a {
        color: #BFAFAF;
        text-decoration: none;
        letter-spacing: 3px;
        font-weight: 700;
        font-size: 1rem;
        position: relative;
        font-family: 'Poppins', sans-serif;
    }

    .nav-links a:hover {
        opacity: 0.9;
    }

    .nav-links a:focus {
        opacity: 0.7;
    }

    .nav-links a::after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        top: 25px;
        width: 0%;
        height: 2px;
        border-radius: 10px;
        margin: auto;
        transition: all .4s;
        opacity: 0;
        background-color: #000;
    }

    .nav-links a:hover::after {
        width: 100%;
        opacity: 1;
    }

    .active {
        color: #000 !important;
        // text-decoration: none;
        // letter-spacing: 3px;
        // font-weight: 700;
        // font-size: 1rem;
        // position: relative;
        // font-family: 'Poppins', sans-serif;
    }

    .burger {
        display: none;
        cursor: pointer;
        z-index: 99;
    }


    .burger .line0 {
        width: 30px;
        height: 3px;
        background: #213654;
        margin: 5px;
        border-radius: 8px;
        transition: all 0.3s ease;
    }

    .burger .line1 {
        width: 30px;
        height: 3px;
        background: #213654;
        margin: 5px;
        border-radius: 8px;
        transition: all 0.3s ease;
    }

    .burger .line2 {
        width: 30px;
        height: 3px;
        background: #213654;
        margin: 5px;
        border-radius: 8px;
        transition: all 0.3s ease;
    }

    .bro {
        display: none;
        cursor: pointer;
        z-index: 99;
    }


    .bro .line0 {
        width: 30px;
        height: 3px;
        margin: 5px;
        border-radius: 8px;
        transform: rotate(-45deg) translate(-5px, 6px);
        background: #213654;
        transition: all 0.3s ease;
    }

    .bro .line1 {
        width: 30px;
        height: 3px;
        background: #213654;
        margin: 5px;
        border-radius: 8px;
        transition: all 0.3s ease;
        opacity: 0;
    }

    .bro .line2 {
        width: 30px;
        height: 3px;
        background: #213654;
        margin: 5px;
        border-radius: 8px;
        transition: all 0.3s ease;
        transform: rotate(42deg) translate(-5px, -7px);
        margin-top: 5px;
    }



    .login {
        a {
            color: #223256;
            border-radius: 12px;
            padding: 10px 15px;

            &:hover {
                background: #223256;
                color: #fff !important;
                opacity: 1 !important;
            }

            &::after {
                position: absolute;
                content: '';
                bottom: 0;
                left: 0;
                top: 25px;
                width: 0%;
                height: 0px;
                border-radius: 10px;
                margin: auto;
                transition: all .4s;
            }
        }

    }

    .request {
        a {
            color: #223256;
            border-radius: 12px;
            padding: 10px 15px !important;
            background: #223256;
            color: #fff !important;

            &:hover {
                opacity: 0.8;
            }

            &::after {
                position: absolute;
                content: '';
                bottom: 0;
                left: 0;
                top: 25px;
                width: 0%;
                height: 0px;
                border-radius: 10px;
                margin: auto;
                transition: all .4s;
            }
        }
    }
}

@media screen and (max-width:1024px) {
    nav {
        // justify-content: space-evenly;

        .nav-links {
            width: 90%;

            a {
                font-size: 0.8rem;
            }
        }
    }

}

@media screen and (max-width:768px) {

    nav {
        .nav-links {
            display: none;
        }

        .nav-links li {
            opacity: 0;
            margin-top: 0;
            font-size: 12px;
        }

        .burger,
        .bro {
            display: block;
        }

        .logo {
            z-index: 5;

            img {
                height: 25px;
                align-self: center;
                justify-self: center;
                margin-top: 5.5px;
            }

        }
    }

    @keyframes navlinkFade {
        from {
            opacity: 0;
            transform: translateX(50px);
        }

        to {
            opacity: 1;
            transform: translateX(0px);
        }
    }
}

.nav-active {
    transform: translate(0%);
    position: absolute;
    right: 0;
    height: 90vh;
    top: 0;
    background: #fff;
    color: #573F7B;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    transition: transform 0.5s ease-in;
    z-index: 4;

    li {
        list-style: none;
    }

    a {
        color: #BFAFAF;
        text-decoration: none;
        letter-spacing: 3px;
        font-weight: 700;
        font-size: 1rem;
        position: relative;
        font-family: 'Poppins', sans-serif;
    }

    a:hover {
        opacity: 0.9;
    }

    a:focus {
        opacity: 0.7;
    }

    a::after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        top: 25px;
        width: 0%;
        height: 2px;
        border-radius: 10px;
        margin: auto;
        transition: all .4s;
        opacity: 0;
        background-color: #000;
    }

    a:hover::after {
        width: 100%;
        opacity: 1;
    }
}

h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 2.6rem;
    color: #333333;
}


p {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 1rem;
    line-height: 24px;
    color: #6E6E6E;
}

h3 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1.875rem;
    line-height: 45px;
    color: #333333;
}

@media screen and (max-width:1024px) {
    h1 {
        font-size: 1.8rem;
    }

    p {
        font-size: 0.8rem;
    }

    h3 {
        font-size: 1.5rem;
    }

}

video {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

//defining my container
.my-container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .my-container {
        width: 100%;
    }
}

@media (min-width: 768px) {
    .my-container {
        width: 100%;
    }
}

@media (min-width: 992px) {
    .my-container {
        width: 960px;
    }
}

@media (min-width: 1200px) {
    .my-container {
        width: 1140px;
    }
}

.newsletterform {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .email-con {
        width: 70%;

        input {
            width: 100%;
            padding: 2%;
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 1rem;
            color: #213654;
            border: 1px solid #D4D4D4;
            border-radius: 10px 0px 0px 12px;
            outline: none;

            &::placeholder {
                color: #BFAFAF;
                font-family: 'Poppins', sans-serif;
            }

            &:focus {
                border: 1px solid #213654;
            }
        }



    }

    .submit-btn {
        width: 30%;

        input {
            width: 100%;
            padding: 4.7%;
            border: 1px solid #213654;
            background: #213654;
            color: #fff;
            outline: none;
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 1rem;
            letter-spacing: 0.0168em;
            border-radius: 0px 10px 12px 0px;

            &:hover {
                background: none;
                color: #213654;
                border: 1px solid #213654;
            }
        }
    }
}

@media screen and (max-width:1024px) {
    .newsletterform {

        .email-con {
            input {
                font-size: 0.8rem;
            }
        }

        .submit-btn {
            width: 30%;

            input {
                font-size: 0.8rem;
            }
        }
    }
}

@media screen and (max-width:768px) {
    .newsletterform {
        flex-direction: column;

        .email-con {
            width: 100%;

            input {
                width: 100%;
                border-radius: 10px 10px 10px 10px;
                outline: none;
            }
        }

        .submit-btn {
            margin-top: 5%;
            width: 80%;

            input {
                border-radius: 10px 10px 10px 10px;
                padding: 2.5%;

                &:hover {
                    background: none;
                    color: #213654;
                    border: 1px solid #213654;
                }
            }
        }
    }
}

//styles for banner.js component
.banner {
    background: #EBF3F3;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
    margin-top: 3%;
    margin-top: 8vh;
    // height: 500px;

    .banner-inner {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    h1,
    p,
    h3 {
        text-align: center;
        margin-bottom: 2.5%;
    }
}

@media screen and (max-width:768px) {
    .banner {
        margin-top: 8vh;

        .banner-inner {
            align-items: flex-start;
        }

        h3 {
            text-align: center;
            margin-left: 50%;
            margin-right: 50%;
            transform: translate(-50%);
            width: 100%;
        }

        h1,
        p {
            text-align: left;
        }
    }
}


//styles for aboutvideos component
.about-videos {
    width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;

    .about-videos-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .about {
            padding: 2%;
        }

        div {
            width: 48%;
            box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.08);
            height: 350px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            border-radius: 12px;

            a,
            p,
            h3 {
                text-align: center;
                margin-bottom: 5%;
            }

            a {
                font-family: 'Poppins', sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 1rem;
                color: #000000;
                text-decoration: none;

                &:hover {
                    opacity: 0.8;
                }
            }

            video {
                border-radius: 12px;
            }
        }
    }
}

@media screen and (max-width:1024px) {
    .about-videos {
        .about-videos-inner {
            div {
                a {
                    font-size: 0.8rem;
                }
            }
        }
    }
}

@media screen and (max-width:768px) {
    .about-videos {

        .about-videos-inner {
            flex-direction: column;

            .about {
                padding: 5%;

                a,
                h3 {
                    text-align: center;
                    margin-bottom: 2%;
                }

                P {
                    text-align: left;
                }
            }

            .video-con {
                margin-top: 5%;
                height: 300px;
            }

            div {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                border-radius: 12px;
                padding: 0%;
            }
        }
    }
}

.features {
    padding-top: 5%;
    padding-bottom: 5%;
    background: #EBF3F3;

    .features-inner {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h1,
        p {
            text-align: center;
            margin-bottom: 2.5%;
        }

        .cont {
            width: 80%;

            .feature {
                width: 100%;
                display: grid;
                grid-template-columns: 33.3% 33.3% 33.3%;
                background: #F2FAFA;
                padding: 1%;


                h3 {
                    text-align: center;
                }
            }

            .feature1 {
                width: 100%;
                display: grid;
                grid-template-columns: 33.3% 33.3% 33.3%;
                background: #fff;

                p {
                    border-bottom: 1px solid #D4D4D4;
                    padding: 5%;
                    margin-bottom: 0;
                }


                .green {
                    color: #91D738;
                }
            }
        }
    }


}

@media screen and (max-width:1024px) {
    .features {

        .features-inner {

            h1 {
                font-size: 1.5rem;
            }


            .cont {
                .feature {
                    h3 {
                        font-size: 1rem;
                        line-height: 1;
                    }
                }

                .feature1 {

                    p {
                        font-size: 0.875rem;
                    }

                }
            }
        }


    }
}

@media screen and (max-width:768px) {
    .features {
        .features-inner {
            .cont {
                width: 100%;

                .feature {
                    padding: 3%;

                    h3 {
                        text-align: center;
                    }
                }

                .feature1 {
                    width: 100%;
                    display: grid;
                    grid-template-columns: 33.3% 33.3% 33.3%;
                    background: #fff;
                    padding: 3%;

                    .left {
                        text-align: left;
                    }

                    .green {
                        color: #91D738;
                    }
                }
            }
        }


    }
}

.why-choose {
    padding-top: 5%;
    padding-bottom: 5%;

    .why-choose-inner {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h3,
        p {
            text-align: center;
            margin-bottom: 2.5%;
        }

        .reason-cont {
            width: 100%;
            margin-top: 5%;

            .resort {
                display: grid;
                grid-template-columns: 25% 25% 25% 25%;
                background: #FFFFFF;




                .resort-inner {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 120px;
                    padding: 5%;
                    border-radius: 10px;
                    margin-bottom: 5%;
                    box-shadow: 10px 32px 50px rgba(51, 51, 51, 0.03);

                    .fin {
                        width: 20%;
                        height: 100%;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }

                    }

                    .p2 {
                        width: 70%;
                        display: flex;
                        justify-content: center;
                        align-items: flex-start;
                        flex-direction: column;

                        p {
                            text-align: left;
                        }

                        .bold {
                            font-weight: bold;
                            font-size: 0.8rem;
                        }

                        .pale {
                            font-size: 0.8rem;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width:1024px) {
    .why-choose {
        .why-choose-inner {

            .reason-cont {
                .resort {
                    .resort-inner {
                        height: 120px;
                        padding: 5%;

                        .p2 {
                            .pale {
                                font-size: 0.6rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width:768px) {
    .why-choose {
        .why-choose-inner {

            .reason-cont {
                width: 100%;
                margin-top: 5%;

                .resort {
                    display: grid;
                    //grid-template-columns: 33.3% 33.3%  33.3%;
                    grid-template-columns: 50% 50%;
                    background: #FFFFFF;

                    .resort-inner {
                        height: 120px;
                        padding: 5%;

                        .p2 {
                            width: 70%;
                            display: flex;
                            justify-content: center;
                            align-items: flex-start;
                            flex-direction: column;

                            p {
                                text-align: left;
                            }

                            .bold {
                                font-weight: bold;
                            }

                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width:540px) {
    .why-choose {
        .why-choose-inner {

            .reason-cont {
                width: 100%;
                margin-top: 5%;

                .resort {
                    display: grid;
                    //grid-template-columns: 33.3% 33.3%  33.3%;
                    grid-template-columns: auto;
                    background: #FFFFFF;

                    .resort-inner {
                        height: 100px;
                        padding: 5%;

                        .p2 {
                            width: 70%;
                            display: flex;
                            justify-content: center;
                            align-items: flex-start;
                            flex-direction: column;

                            p {
                                text-align: left;
                            }

                            .bold {
                                font-weight: bold;
                            }

                        }
                    }
                }
            }
        }
    }
}

.products {
    padding-top: 5%;
    padding-bottom: 5%;
    background: #EBF3F3;

    .inner {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h3,
        p {
            text-align: center;
            margin-bottom: 2.5%;
        }

        .product {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            border-radius: 16px;
            margin-top: 5%;

            .Standard {
                background: #213654;
                border-radius: 16px;
                // height: 500px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                box-shadow: 0px 8px 32px rgba(59, 59, 59, 0.04) !important;
                width: 30%;
                padding: 2%;
                border-radius: 16px;

                .icon {
                    color: #F4BC4D;
                }

                .starred {
                    display: block;
                    margin-bottom: 5%;
                    margin-top: 5%;
                    font-size: 0.75rem;
                }

                h1 {
                    font-size: 2.5rem;
                }

                h3 {
                    font-size: 1.8rem;
                }

                p {
                    font-size: 0.875rem;
                }

                h1,
                h3,
                p {
                    color: #fff;
                }

                .snip {
                    display: block;
                    width: 100%;
                    margin-bottom: 5%;
                    margin-top: 5%;
                }

                div {
                    margin-bottom: 5%;
                    width: 90%;

                    p {
                        text-align: left;
                        color: #fff;
                        margin-bottom: 5%;

                        .con {
                            color: #fff;
                        }
                    }
                }

                a {
                    font-family: "Poppins", sans-serif;
                    font-weight: normal;
                    font-size: 1.25rem;
                    line-height: 30px;
                    text-align: center;
                    background: #fff;
                    color: #213654;
                    display: block;
                    width: 100%;
                    text-decoration: none;
                    padding: 2%;
                    margin-top: 5%;
                    border-radius: 12px;
                    margin-bottom: 5%;

                    &:hover {
                        border: 1px solid #fff;
                        background: none;
                        color: #fff;
                    }
                }

            }

            .Basic,
            .Enterprise {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                box-shadow: 0px 8px 32px rgba(59, 59, 59, 0.04) !important;
                width: 30%;
                padding: 2%;
                border-radius: 16px;
                background: #fff;
                // height: 400px;

                .starred {
                    display: none;
                }

                .icon {
                    color: #F4BC4D;
                }

                h1 {
                    font-size: 2.5rem;
                }

                h3 {
                    font-size: 1.8rem;
                }

                p {
                    font-size: 0.875rem;
                }

                h1,
                h3,
                p {
                    text-align: center;
                    margin-bottom: 2.5%;
                }

                .snip {
                    display: block;
                    width: 100%;
                    margin-bottom: 5%;
                    margin-top: 5%;
                }

                div {
                    margin-bottom: 5%;
                    width: 90%;

                    p {
                        text-align: left;
                        color: #6E6E6E;
                        margin-bottom: 3%;

                        .con {
                            color: #213654;
                        }
                    }
                }

                a {
                    font-family: "Poppins", sans-serif;
                    font-weight: normal;
                    font-size: 1.25rem;
                    line-height: 30px;
                    text-align: center;
                    background: #213654;
                    color: #FFFFFF;
                    display: block;
                    width: 100%;
                    text-decoration: none;
                    padding: 2%;
                    margin-top: 5%;
                    border-radius: 12px;

                    &:hover {
                        border: 1px solid #213654;
                        background: none;
                        color: #213654;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:1024px) {
    .products {

        .inner {

            .product {

                .Standard {

                    .icon {
                        color: #F4BC4D;
                    }

                    .starred {
                        font-size: 0.625rem;
                    }

                    h1 {
                        font-size: 2rem;
                    }

                    h3 {
                        font-size: 1.2rem;
                    }

                    p {
                        font-size: 0.75rem;
                    }




                    a {
                        font-size: 1rem;
                    }

                }

                .Basic,
                .Enterprise {

                    h1 {
                        font-size: 2rem;
                    }

                    h3 {
                        font-size: 1.2rem;
                    }

                    p {
                        font-size: 0.75rem;
                    }

                    a {
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:768px) {
    .products {

        .inner {
            .product {
                flex-direction: column;

                .Standard {
                    width: 100%;
                    padding: 5%;
                    margin-top: 5%;
                    margin-bottom: 5%;

                    .starred {
                        margin-bottom: 5%;
                        margin-top: 5%;
                    }

                    .snip {
                        display: block;
                        width: 100%;
                        margin-bottom: 5%;
                        margin-top: 5%;
                    }

                    div {
                        margin-bottom: 5%;
                        width: 100%;
                        padding: 5%;

                        p {
                            text-align: left;
                            color: #fff;
                            margin-bottom: 5%;

                            .con {
                                color: #fff;
                            }
                        }
                    }

                    a {

                        padding: 2%;
                        margin-top: 5%;
                        margin-bottom: 5%;
                    }

                }

                .Basic,
                .Enterprise {
                    width: 100%;
                    padding: 5%;

                    div {
                        width: 100%;
                        padding: 5%;

                        p {
                            text-align: left;
                            color: #6E6E6E;
                            margin-bottom: 3%;
                        }
                    }

                    a {
                        padding: 2%;
                        margin-top: 5%;
                        margin-bottom: 5%;
                    }
                }
            }
        }
    }
}


.testimonies {
    padding-top: 5%;
    padding-bottom: 5%;

    .inner {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h1,
        p {
            text-align: center;
            margin-bottom: 2.5%;
        }

        .testimony {
            display: grid;
            grid-template-columns: 33.3% 33.3% 33.3%;
            margin-top: 2%;

            .bless {
                .comment-cont {
                    padding: 5%;
                    box-shadow: 10px 32px 72px rgba(51, 51, 51, 0.03);
                    border-radius: 10px;

                    p {
                        text-align: left;
                    }
                }

                .nam-rat {
                    margin-top: 5%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 5%;

                    .nam-rat-in {
                        display: flex;
                        align-items: center;

                        .img-con {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 40px;
                            width: 40px;
                            border-radius: 50%;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                        }

                        .name-con {

                            p {
                                text-align: left;
                                margin-left: 10px;
                                font-family: 'Ubuntu', sans-serif;
                            }

                            .bold {
                                font-weight: bold;
                            }

                            .pale {
                                font-size: 0.75rem;
                            }

                        }
                    }

                    .rating-con {
                        display: grid;
                        grid-template-columns: auto auto;

                        .icon {
                            color: #F4BC4D;
                        }

                        p {
                            font-family: 'Ubuntu', sans-serif;
                            color: #D4D4D4;
                            margin-left: 10px;
                        }
                    }
                }

            }
        }
    }
}

.options {
    background: #EBF3F3;
    padding-top: 5%;
    padding-bottom: 5%;

    h3,
    p {
        margin-bottom: 1%;
        color: #fff;
    }

    .inner {
        display: flex;
        justify-content: center;
        flex-direction: column;

        div {
            padding: 5%;
            border-radius: 20px;

        }

        .mechanic {
            background: url("../blue.svg");
            background-size: cover;
            background-repeat: no-repeat;

            a {
                text-decoration: none;
                background: #fff;
                width: 20%;
                padding: 1%;
                border-radius: 10px;
                margin-top: 2%;
                text-align: center;
                display: block;
                color: #292B4D;

                &:hover {
                    border: 1px solid #fff;
                    color: #fff;
                    background: none;
                    transform: translateX(10px);
                    transition: all 1s;
                }
            }
        }

        .spare {
            margin-top: 2%;
            background: url("../green.svg");
            background-size: cover;
            background-repeat: no-repeat;


            a {
                text-decoration: none;
                background: #292B4D;
                width: 20%;
                padding: 1%;
                border-radius: 10px;
                margin-top: 2%;
                text-align: center;
                display: block;
                color: #fff;

                &:hover {
                    border: 1px solid #292B4D;
                    color: #292B4D;
                    background: none;
                    transform: translateX(10px);
                    transition: all 1s;
                }
            }
        }
    }


}

.waitlist {
    padding-top: 5%;
    padding-bottom: 5%;
    background: #fff;

    .inner {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h3,
        p {
            text-align: center;
            margin-bottom: 2.5%;
        }

        .news-con {
            width: 80%;
            padding: 3%;
            background: #EBF3F3;
            border-radius: 16px;
            border: 1px solid #D4D4D4;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            p {
                margin-top: 3%;
                font-size: 0.8rem;

                a {
                    text-decoration: underline;
                    color: #213654;
                    font-weight: bold;
                    text-transform: capitalize;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }
    }
}

footer {
    background: #EBF3F3;
    padding-top: 5%;
    padding-bottom: 5%;

    .inner {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .foot-cont {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .cont {
                width: 30%;
                height: 100%;

                .img-con {
                    height: 48px;
                    width: 50%;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                p {
                    margin-top: 0%;
                    margin-left: 5%;
                    color: #333333;
                    font-size: 0.8rem;
                }

                .icons-con {
                    margin-left: 5%;
                    margin-top: 1%;
                    width: 100%;
                    width: 50%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    a {
                        color: #333;
                        text-decoration: none;
                        font-size: 1.5rem;

                        &:hover {
                            opacity: 0.8;
                        }
                    }
                }
            }

            .links-main {
                width: 70%;
                display: grid;
                grid-template-columns: auto auto auto;

                div {

                    h1 {
                        font-size: 1.8rem;
                        font-weight: 600;
                        color: #333333;
                    }

                    p {
                        margin-top: 2%;
                        color: #333333;
                        font-size: 0.8rem;

                        a {
                            text-decoration: none;
                            color: #333333;
                            position: relative;

                            &:hover {
                                opacity: 0.9;
                            }

                            &:focus {
                                opacity: 0.7;
                            }

                            &::after {
                                position: absolute;
                                content: '';
                                bottom: 0;
                                left: 0;
                                top: 25px;
                                width: 0%;
                                height: 2px;
                                border-radius: 10px;
                                margin: auto;
                                transition: all .4s;
                                opacity: 0;
                                background-color: #333;
                            }

                            &:hover::after {
                                width: 100%;
                                opacity: 1;
                            }
                        }

                    }
                }
            }
        }

        .rights {
            margin-top: 2%;
            border-top: 1px solid #6E6E6E;
            width: 100%;
            padding: 2%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
                font-size: 0.8rem;

                a {
                    text-decoration: none;
                    color: #333333;
                    position: relative;

                    &:hover {
                        opacity: 0.9;
                    }

                    &:focus {
                        opacity: 0.7;
                    }

                    &::after {
                        position: absolute;
                        content: '';
                        bottom: 0;
                        left: 0;
                        top: 25px;
                        width: 0%;
                        height: 2px;
                        border-radius: 10px;
                        margin: auto;
                        transition: all .4s;
                        opacity: 0;
                        background-color: #333;
                    }

                    &:hover::after {
                        width: 100%;
                        opacity: 1;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:768px) {

    .testimonies,
    .options,
    .waitlist,
    footer {
        display: none;
    }
}