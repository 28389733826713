.stats_wrapper::-webkit-scrollbar {
  width: 5px !important;
  cursor: pointer;
  height: 8px !important;
}

/* Track */
.stats_wrapper::-webkit-scrollbar-track {
  cursor: pointer;
  background-color: transparent;
}

/* Handle */
.stats_wrapper::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: #bbb;
  height: 4px !important;
  width: 4px !important;
}
