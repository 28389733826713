.timeline__wrapper {
  position: relative;
  overflow: hidden;
}
/* 
.timeline__wrapper::after {
    position: absolute;
    top: -140px;
    right: 200px;
    width: 200px;
    border-radius: 200px;
    height: 200px;
    content: "";
    border: 1px solid rgba(212, 249, 211, 0.5);
    background: linear-gradient(37.06deg, #7EC67D 21.51%, #74B973 80.73%);
    opacity: 0.5;
}

.timeline__wrapperSecondCircle,
.timeline__wrapperCircle,
.timeline__wrapper::before {
    position: absolute;
    width: 259.21px;
    height: 273.37px;
    border-radius: 200px;
    right: 150px;
    top: 143px;
    z-index: 2;
    border: 1px solid rgba(212, 249, 211, 0.5);
    content: "";
    background: linear-gradient(238.41deg, #92DD91 -5.76%, rgba(116, 185, 115, 0) 54.39%);
    opacity: 0.5;
}

.timeline__wrapperSecondCircle,
.timeline__wrapperCircle {
    background: linear-gradient(137.76deg, #FFFFFF 18.03%, rgba(255, 255, 255, 0) 92.51%);
    opacity: 0.1;
}

.timeline__wrapperCircle {
    left: -70px;
    top: -20px;
}

.timeline__wrapperSecondCircle {
    right: -80px;
    top: -50px;
}
 */

.timeline {
  margin-top: 15px;
  padding-top: 15px;
  width: 100%;
  position: relative;
}

.timeline::after {
  width: 95%;
  position: absolute;
  border-top: 1px solid #fff;
  content: "";
  /* margin: auto; */
  background-color: var(--fixbot-blue);
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
}

.timeline > div {
  position: relative;
  width: 100%;
}

.timeline > div > span {
  position: absolute;
  top: -28px;
  z-index: 2;
  left: -2px;
}

.timeline > div:nth-child(2) > span {
  left: 10px;
}

.timeline > div:nth-child(3) > span {
  right: 0;
}

@media (max-width: 640px) {
  .timeline__wrapper {
    margin-bottom: 10px;
  }
}
