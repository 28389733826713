.map__inner {
  min-height: 300px;
  height: 100%;
  max-height: 450px;
  width: 100%;
  /* min-width: 150px; */
  border-radius: 10px;
  overflow: hidden;
  /* position: relative; */
  margin-bottom: 20px;
  border: 3px solid var(--bg-color);
  /* box-shadow: 0 0 7px rgba(0, 0, 0, 0.3); */
}

.map__inner button {
  display: none;
}
