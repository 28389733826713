.stackedcard {
  border-radius: 15px;
  overflow: hidden;
  max-height: 400px;
  background-color: var(--fixbot-blue);
}

.stackedcard img {
  height: 220px;
}

.stackedcard * {
  color: var(--white);
}

.stackedcard h1 {
  font-weight: bold;
  font-size: 18px;
}

.stackedcard span {
  font-size: 0.9rem;
}
