@import "react-big-calendar/lib/css/react-big-calendar.css";
/* @import "react-bigs-calendar/lib/addons/dragAndDrop/styles"; */

/* button */
.css-1nxhruk[disabled],
.css-1nxhruk[aria-disabled="true"],
.css-1nxhruk[data-disabled] {
  opacity: 0.9;
  cursor: not-allowed;
  box-shadow: var(--chakra-shadows-none);
}
.css-1nxhruk:hover[disabled],
.css-1nxhruk[data-hover][disabled],
.css-1nxhruk:hover[aria-disabled="true"],
.css-1nxhruk[data-hover][aria-disabled="true"],
.css-1nxhruk:hover[data-disabled],
.css-1nxhruk[data-hover][data-disabled] {
  background: auto !important;
}

div.rbc-date-cell {
  /* display: flex; */
  /* height: 134px; */
  /* align-self: center; */
  /* justify-content: center; */
  background: transparent;
  text-align: center;
  position: relative;
}

.rbc-header {
  height: 40px;
  display: grid;
  place-items: center;
}
.rbc-day-bg.rbc-today {
  background: #213654 !important;
  border-radius: 4px;
}
.rbc-date-cell.rbc-now.rbc-current {
  color: #fff !important;
}
/* 
.rbc-button-link {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
}
*/
.rbc-toolbar {
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
}

.rbc-toolbar > *:nth-child(1) {
  padding-bottom: 8px;
}
.rbc-toolbar > *:nth-child(2) {
  font-weight: bold;
  font-size: 16px;
}
.rbc-toolbar > *:nth-child(3) {
  padding-top: 8px;
}
.rbc-agenda-content {
  z-index: 1;
  position: relative;
}
.rbc-agenda-table {
  position: sticky;
  background-color: #fff;
  z-index: 4;
  top: 0;
}

.rbc-agenda-table thead tr {
  display: flex;
}
.rbc-agenda-table thead tr > *:nth-child(1) {
  flex-basis: 14%;
}
.rbc-agenda-table thead tr > *:nth-child(2) {
  flex-basis: 22%;
}
.rbc-agenda-table thead tr > *:nth-child(3) {
  flex: 3;
  flex-basis: 63%;
}

body::-webkit-scrollbar {
  width: 8px;
  cursor: pointer;
}

/* Track */
body::-webkit-scrollbar-track {
  cursor: pointer;
  margin-right: 4px;
  background-color: transparent;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #bbb;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: #21365477;
  opacity: 0.5;
  margin-right: 4px;
  height: 30px !important;
  max-height: 30px !important;
  border-radius: 3px;
  margin: 4px;
}

.my-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .my-container {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .my-container {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .my-container {
    width: 960px;
  }
}
@media (min-width: 1200px) {
  .my-container {
    width: 1140px;
  }
}
