.historyChart {
    position: relative;
}

.chartContainer {
    position: relative;
}

.chartContainer>canvas {
    position: absolute;
    min-width: 80%;
    max-height: 250px;
    min-height: 220px;
    position: relative;
    top: 0px;
    max-width: 95%;
    margin: auto;
    margin-top: 20px;
}

.historyChart__select {
    position: absolute;
    right: 10px;
    top: 10px;
}

.historyChart__select * {
    cursor: pointer;
}

.historyChart__select svg,
.historyChart__select select {
    font-size: 0.9rem !important;
    color: var(--white) !important;
}

.historyChart__select select option {
    color: var(--text-color);
    height: 30px;
}