.notificationcard {
  padding: 15px 20px;
  border-radius: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.notificationcard a {
  padding: 3px 6px;
  font-weight: bold;
  border-radius: 4px;
  word-spacing: 3px;
  width: fit-content;
  font-size: 14.5px;
}

.notificationcard a:hover {
  text-decoration: none;
}

.notificationcard p {
  padding-bottom: 15px;
}

@media (max-width: 768px) {
  .notificationcard a {
    margin-left: auto;
    background-color: transparent;
  }
}
