.map__inner {
    min-height: 160px;
    /* max-height: vh; */
    width: 100%;
    height: calc(100vh - 50px);
    min-width: 150px;
    /* border-radius: 15px; */
    overflow: hidden;
    position: absolute;
    margin-bottom: 20px;
    /* border: 5px solid var(--bg-color); */
    /* box-shadow: 0 0 7px rgba(0, 0, 0, .3); */
}

.map__inner button {
    display: none;
}