.schedule__create {
  background-color: var(--white);
  width: 100%;
  max-width: 600px;
  margin: 40px auto 20px;
  border-radius: 6px;
  padding: 30px;
}

.schedule__create img {
  text-align: center;
  margin: 0 auto 20px;
}

.schedule__create button {
}
